/** @format */

import { TRoom } from '@/ducks/room/declarations';

export interface EvaluedExitWindows {
  startDate: string;
  endDate: string;
  evaluedPrice: number;
  ranking: number;
}

export interface ValidCheckOutDates {
  from: string;
  to: string;
  validDates: string[];
  evaluatedDates: EvaluedExitWindows[];
}

export interface RoomAvailability {
  price: number;
  discountedPrice?: number;
  discountUntil?: string;
  visibleOnline: boolean;
  availableFrom: string;
  availableTo: string;
  bedAvailabilityId: string;
  bedCode: string;
  checkInDates: string[];
  negotiating: boolean;
  status: 'Free' | '';
  checkoutDateFrom: string;
  checkoutDateTo: string;
  minimumStay: number;
  salableOnline: boolean;
  defaultCheckoutDate: string;
  validCheckoutDates: string[];
  evaluedExitWindows?: EvaluedExitWindows[];
}

export interface ContractualConditions {
  adminFeeIn: number;
  adminFeeOut: number;
  cancellationMonthNumber: number;
  cityId: string;
  code: string;
  contractModel: 'CNTIND' | 'CNTDET';
  contractualConditionId: 'e234900b-adec-ec11-bb3d-000d3adc5e94';
  durationFrom: number;
  durationTo: number;
  legalContractType: 'Type5';
  legalEntityId: string;
  name: string;
  paymentOption: string;
  type: 'Uncertain';
}

export interface CommercialConditions {
  confirmationDeposit: number;
  depositAmount: number;
  depositMonthsNumber: number;
  discountPercentage: number;
  discountUntil: string;
  exitOptions: boolean;
  firstMonthRent: number;
  maxVacancyDays: number;
  minStayDays: number;
  preCleaningDays: number;
  priceVariationPercentage: number;
  price: number;
  commercialConditionsId: string;
  privateSale: boolean;
  discountedPrice: number;
}

export interface BookingContactDetails {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  privacyPolicy: boolean;
  marketingAccepted?: boolean;
}

export enum GenderOptions {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum ProfessionOptions {
  STUDENT = 'Student',
  WORKER = 'Worker',
  INTERN = 'Intern',
  OTHER = 'Other',
}

export enum BookingErrorStates {
  GENERIC_API = 'generic_api',
  NO_AVAILABILITY = 'no_availability',
}

export enum SecondOccupantOptions {
  NO = 'no',
  YES = 'yes',
}

export interface BookingPersonalDetails {
  sex?: GenderOptions;
  nationality?: string;
  countryOfBirth?: string;
  cityOfBirth?: string;
  dateOfBirth?: string;
  taxCode?: string;
  occupation?: ProfessionOptions;
  preferredLanguage?: string;
}

export interface SecondOccupantYes {
  secondOccupant: SecondOccupantOptions.YES;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
}

interface SecondOccupantNo {
  secondOccupant: SecondOccupantOptions.NO;
}

export type BookingSecondOccupant = SecondOccupantYes | SecondOccupantNo;

export interface BookingResidentialAddress {
  country: string;
  province: string;
  city: string;
  zipCode: string;
  address: string;
  houseNumber: string;
}

export interface BookingCountry {
  value: string;
  label: string;
  countryCode: string;
  isEu: boolean;
}

export interface BookingNationality {
  country: string;
  enName: string;
  id: string;
  isEuCountry: boolean;
  iso3Code: string;
  itName: string;
  guarantorRequired: boolean;
}

export interface BookingValueLabel {
  value: string;
  label: string;
}

export interface BookingOptions {
  countries: BookingCountry[];
  genders: BookingValueLabel[];
  nationalities: BookingNationality[];
  prefixes: BookingValueLabel[];
  professions: BookingValueLabel[];
  provinces: BookingValueLabel[];
}

export enum FinancialProvider {
  MYSELF = 'OwnGuarantor',
  GUARANTOR = 'OneGuarantor',
  GARAMTME = 'Garantme',
  SMARTGARANT = 'Smartgarant',
  STUDAPART = 'Studapart',
  NO_GUARANTOR = 'NoGuarantor',
}

interface FinancialValidationNoForm {
  provider:
    | FinancialProvider.MYSELF
    | FinancialProvider.GARAMTME
    | FinancialProvider.SMARTGARANT
    | FinancialProvider.STUDAPART
    | null;
}

interface FinancialValidationWithForm {
  provider: FinancialProvider.GUARANTOR;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export type BookingFinancialValidation = FinancialValidationNoForm | FinancialValidationWithForm;

export interface BookingApplicationForm {
  checkInDate: string;
  checkOutDate: string;
  validCheckOutDates?: ValidCheckOutDates;
  contactDetails: BookingContactDetails;
  contactDetailsErrors: boolean;
  personalDetails: BookingPersonalDetails;
  personalDetailsErrors: boolean;
  secondOccupant: BookingSecondOccupant;
  secondOccupantErrors: boolean;
  residentialAddress: BookingResidentialAddress;
  residentialAddressErrors: boolean;
  financialValidation: BookingFinancialValidation;
  financialValidationErrors: boolean;
  howDidYouFindUs: string;
  howDidYouFindUsErrors: boolean;
  joivyBookingInfo?: {
    commercialConditionsId: string;
  };
}

type BookingErrorLightIdentity = {
  [key: string]: string[];
};

export type BookingError = {
  name?: string;
  reason?: string;
}[];

export type BookingStep = 'contacts' | 'personalDetails' | 'financialValidation' | 'payment';

export type BookingPayment = {
  idStatus?: string;
  legalEntity?: string;
} & (BookingPaymentStripe | BookingPaymentPayline);

export type BookingPaymentStripe = {
  stripePaymentID: string;
  paylinePaymentID: null;
};

export type BookingPaymentPayline = {
  stripePaymentID: null;
  paylinePaymentID: string;
};

export type BookingCouponCode = {
  code?: string;
  discountAmount?: number;
};

export interface TReducerBooking {
  formId: string;
  room?: TRoom;
  bookingOptions: BookingOptions;
  availability?: RoomAvailability;
  contractualConditions?: ContractualConditions;
  commercialConditions?: CommercialConditions;
  lightIdentity?: {
    lightIdentityStatus: 'Created'; // TBD
  };
  applicationForm: BookingApplicationForm;
  loaders: string[];
  error?: BookingErrorStates;
  step: BookingStep;
  paymentInfo?: BookingPayment;
  errors: {
    [key in ErrorsTypeFields]?: BookingError | BookingErrorStates; // blocking errors
  };
  couponCode: BookingCouponCode;
}

export type ErrorsTypeFields =
  | 'contactDetails'
  | 'personalDetails'
  | 'secondOccupant'
  | 'residentialAddress'
  | 'financialValidation'
  | 'panicError'
  | 'generic';
