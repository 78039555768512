exports.components = {
  "component---src-components-page-page-blog-post-index-tsx": () => import("./../../../src/components/page/PageBlogPost/index.tsx" /* webpackChunkName: "component---src-components-page-page-blog-post-index-tsx" */),
  "component---src-components-page-page-blog-tag-index-tsx": () => import("./../../../src/components/page/PageBlogTag/index.tsx" /* webpackChunkName: "component---src-components-page-page-blog-tag-index-tsx" */),
  "component---src-components-page-page-booking-new-index-tsx": () => import("./../../../src/components/page/PageBookingNew/index.tsx" /* webpackChunkName: "component---src-components-page-page-booking-new-index-tsx" */),
  "component---src-components-page-page-city-index-tsx": () => import("./../../../src/components/page/PageCity/index.tsx" /* webpackChunkName: "component---src-components-page-page-city-index-tsx" */),
  "component---src-components-page-page-district-index-tsx": () => import("./../../../src/components/page/PageDistrict/index.tsx" /* webpackChunkName: "component---src-components-page-page-district-index-tsx" */),
  "component---src-components-page-page-help-center-index-tsx": () => import("./../../../src/components/page/PageHelpCenter/index.tsx" /* webpackChunkName: "component---src-components-page-page-help-center-index-tsx" */),
  "component---src-components-page-page-info-request-received-index-tsx": () => import("./../../../src/components/page/PageInfoRequestReceived/index.tsx" /* webpackChunkName: "component---src-components-page-page-info-request-received-index-tsx" */),
  "component---src-components-page-page-listing-index-tsx": () => import("./../../../src/components/page/PageListing/index.tsx" /* webpackChunkName: "component---src-components-page-page-listing-index-tsx" */),
  "component---src-components-page-page-locations-index-tsx": () => import("./../../../src/components/page/PageLocations/index.tsx" /* webpackChunkName: "component---src-components-page-page-locations-index-tsx" */),
  "component---src-components-page-page-login-index-tsx": () => import("./../../../src/components/page/PageLogin/index.tsx" /* webpackChunkName: "component---src-components-page-page-login-index-tsx" */),
  "component---src-components-page-page-newsletter-privacy-policy-index-tsx": () => import("./../../../src/components/page/PageNewsletterPrivacyPolicy/index.tsx" /* webpackChunkName: "component---src-components-page-page-newsletter-privacy-policy-index-tsx" */),
  "component---src-components-page-page-privacy-policy-index-tsx": () => import("./../../../src/components/page/PagePrivacyPolicy/index.tsx" /* webpackChunkName: "component---src-components-page-page-privacy-policy-index-tsx" */),
  "component---src-components-page-page-room-deprecated-index-tsx": () => import("./../../../src/components/page/PageRoomDeprecated/index.tsx" /* webpackChunkName: "component---src-components-page-page-room-deprecated-index-tsx" */),
  "component---src-components-page-page-room-index-tsx": () => import("./../../../src/components/page/PageRoom/index.tsx" /* webpackChunkName: "component---src-components-page-page-room-index-tsx" */),
  "component---src-components-page-page-tour-request-confirmed-index-tsx": () => import("./../../../src/components/page/PageTourRequestConfirmed/index.tsx" /* webpackChunkName: "component---src-components-page-page-tour-request-confirmed-index-tsx" */),
  "component---src-components-page-page-tour-request-index-tsx": () => import("./../../../src/components/page/PageTourRequest/index.tsx" /* webpackChunkName: "component---src-components-page-page-tour-request-index-tsx" */),
  "component---src-components-page-page-what-you-will-get-index-tsx": () => import("./../../../src/components/page/PageWhatYouWillGet/index.tsx" /* webpackChunkName: "component---src-components-page-page-what-you-will-get-index-tsx" */),
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-booking-completed-ts": () => import("./../../../src/pages/booking-completed.ts" /* webpackChunkName: "component---src-pages-booking-completed-ts" */),
  "component---src-pages-booking-failed-ts": () => import("./../../../src/pages/booking-failed.ts" /* webpackChunkName: "component---src-pages-booking-failed-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

