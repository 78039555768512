/** @format */

import { TListingAction } from '@/redux/declarations';
import { District, POI } from '@/types/apiBeds';
import { call, put } from 'redux-saga/effects';
import { apiSearch } from '../../api/apiSearch';
import { TApiSearchParams } from '../../types/apiSearch';
import { MAX_FILTER_PRICE, MIN_FILTER_PRICE } from './constants';
import { ListingFilters, ListingStore, SortingFields } from './types';

export function* sagaListing(action: TListingAction): Generator<any> {
  try {
    if (!action.payload.skipApi) {
      const params = marshalFiltersTo(action.payload);
      const payload = yield call(apiSearch, params);
      yield put({ type: 'LISTING_SET_RESULT_SUCCESS', payload });
    } else {
      yield put({ type: 'LISTING_SET_LOADING', payload: false });
    }
  } catch (error) {
    yield put({ type: 'LISTING_SET_RESULT_FAILURE' });
  }
}

type FilterInput = Omit<ListingStore, 'results' | 'resultCount'>;

export function marshalFiltersTo(filterInput: FilterInput): TApiSearchParams {
  const objectResponse: TApiSearchParams = {
    language: filterInput.language,
    query: filterInput.query ?? '',
    pageIndex: filterInput.pageIndex,
    orderBy: filterInput.filters.orderBy ?? SortingFields.NULL,
    checkInDate: marshalCheckInDate(filterInput.filters.checkInDate),
    positions: marshalPositions(filterInput.filters.positions),
    zipcodes: marshalDistricts(filterInput.filters.districts),
    filters: marshalFilters(filterInput.filters),
    areas: filterInput.filters.areas,
  };

  return objectResponse;
}

export const calcTenantConstraintType = (
  anyGender: boolean,
  femaleOnly: boolean,
): 'NoRestriction' | 'WomenOnly' | undefined => {
  if (anyGender && femaleOnly) return undefined; // ?? TODO to be discussed
  if (anyGender) return 'NoRestriction';
  if (femaleOnly) return 'WomenOnly';

  return undefined;
};

export const marshalCheckInDate = (checkInDate: string) => checkInDate || new Date().toJSON();
export const marshalPositions = (positions: POI[]) =>
  positions.map(single => ({
    latitude: single.latitude,
    longitude: single.longitude,
  }));
export const marshalDistricts = (districts: District[]) => districts.map(district => `${district.zipCode}`);

export const marshalFilters = (filters: ListingFilters) => {
  return {
    airConditioning: filters.amenities?.airConditioning ?? false,
    balcony: filters.amenities?.balcony ?? false,
    bikeParking: filters.facilities?.bikeParking ?? false,
    clothesDryer: filters.amenities?.clothesDryer ?? false,
    concierge: filters.facilities?.concierge ?? false,
    internalSide: filters.amenities?.internalSide ?? false,
    lowerFloor: filters.facilities?.lowerFloor ?? false,
    topFloor: filters.facilities?.topFloor ?? false,
    privateBathroom: filters.amenities?.privateBathroom ?? false,
    elevator: filters.facilities?.elevator ?? false,
    colivingBuilding: filters.propertyType?.colivingBuilding ?? false,
    singleRoom: filters.propertyType?.singleRoom ?? false,
    entireStudio: filters.propertyType?.entireStudio ?? false,
    oneBedroomApartment: filters.propertyType?.oneBedroomApartment ?? false,
    tenantRestrictionType: calcTenantConstraintType(
      filters.suitability?.anyGender ?? false,
      filters.suitability?.femaleOnly ?? false,
    ),
    twoOccupantsAllowed: filters.suitability?.twoOccupantsAllowed || undefined,
    monthlyPrice: {
      min: filters.monthlyPrice?.min ?? MIN_FILTER_PRICE,
      max: filters.monthlyPrice?.max ?? MAX_FILTER_PRICE,
    },
  };
};
