/** @format */

import { rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';

import ArrowLeft from '@/assets/joivy_arrow_left.svg';
import ArrowRight from '@/assets/joivy_arrow_right.svg';

import { mediaQuery } from './mediaQuery';

const breakpoints = {
  xs: '768px',
  sm: '960px',
  md: '1024px',
  lg: '1160px',
  xl: '1440px',
};

const colors = {
  white: '#FFFFFF',
  black: '#282828',
  orange: '#F45D48',
  red: '#E93636',
  lightGray: '#F5F6F5',
  alternativeGrey: '#AEB8C0',
  alternativeGreyLight: '#EEEEEE',
  darkGrey: '#667085',
  checkboxGrey: '#D0D5DD',
  textHover: '#0000001A',
  green: '#3ED598',
  joivy: {
    'teal-dark': '#015962',
    'teal-dark-700': '#005058',
    'teal-light': '#3aadb4',
    pink: '#e56e69',
    'pink-dark': 'hsl(2, 70.50%, 59%)',
    'pink-light': '#ed9b9a',
    black: '#000',
    white: '#fff',
    yellow: '#FFE84C',
    accent: {
      pink: '#facfc7',
      orange: 'rgb(240, 166, 66)',
      red: 'rgb(237, 105, 66)',
      green: 'rgb(33, 176, 150)',
      purple: 'rgb(143, 36, 87)',
      gray: 'rgb(166,184, 196)',
      'gray-dark': 'hsl(204, 20.00%, 30.00%)',
      'gray-light': 'hsl(204, 20.00%, 90.00%)',
    },
  },
};

const theme = {
  font: {
    title: `'Unbounded', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`,
    subtitle: `'Trirong', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`,
    body: `'Figtree', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`,
    size: {
      '0.6875': '0.6875rem',
      '0.9375': '0.9375rem',
      '1': '1rem',
      '1.125': '1.125rem',
      '1.25': '1.25rem',
      '1.375': '1.375rem',
      '1.4375': '1.4375rem',
      '1.5': '1.5rem',
      '1.75': '1.75rem',
      '2.0625': '2.0625rem',
      '2.375': '2.375rem',
      '2.8125': '2.8125rem',
      '3.125': '3.125rem',
      '3.3125': '3.3125rem',
      '3.5': '3.5rem',
      '4.375': '4.375rem',
      '4.875': '4.875rem',
      '6': '6rem',
      '7.1875': '7.1875rem',
    },
  },
  device: {
    mobile: `(min-width: ${breakpoints.xs})`,
    mobileL: `(min-width: ${breakpoints.sm})`,
    tablet: `(min-width: ${breakpoints.md})`,
    desktop: `(min-width: ${breakpoints.lg})`,
    desktopL: `(min-width: ${breakpoints.xl})`,
  },
  breakpoints,
  colors,
};

export default theme;

export const GlobalFontsStyle = createGlobalStyle`

  /* TYPOGRAPHY RESETS */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.font.title};
    font-weight: 400;
  }


  /* EXTRAS */
  .swiper-pagination .swiper-pagination-clickable .swiper-pagination-bullets {
  z-index: 10;
}


.swiper-pagination,
.swiper-pagination-clickable,
.swiper-pagination-bullets,
.swiper-pagination-bullets-dynamic {
  min-height: 11px !important;
}

.swiper-pagination-bullet {
  background-color: ${rgba(colors.joivy.white, 0.6)};
  opacity: 1;
  width: 6px;
  height: 6px;
}

.swiper-pagination-bullet-active {
  background-color: ${colors.joivy.white};
  width: 10px;
  height: 10px;
  margin-bottom: -2px !important;
}

.swiper-pagination-bullet:only-child {
  display: none;
}

.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev {
  transform: scale(1.2) !important;
}

.swiper-pagination-bullet-active-next-next,
.swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.5) !important;
}

.swiper-button-prev {
  color: ${colors.white};
  left: 25px !important;
}
.swiper-button-next {
  color: ${colors.white};
  right: 25px !important;
}


// swiper whatyouwillget

.swiperWywg .swiper-button-prev {
  top: 30% ;
  content: url(${ArrowLeft});
  width: 20px;
  height: 20px;
  left: 5px !important;

  ${mediaQuery.lg`
    top: 50%;
    width: 50px;
    height: 50px;
`}
}

.swiperWywg .swiper-button-next {
  top: 30% ;
  content: url(${ArrowRight});
  width: 20px;
  height: 20px;
  left: 94%;

  ${mediaQuery.lg`
    top: 50% ;
    width: 50px;
    height: 50px;
  `}
}

.swiperWywg .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  display: none;
}

.swiperWywg .swiper-wrapper {
  height: fit-content;

  ${mediaQuery.lg`
    height: 100%;
  `}
}

.red-circle-legacy {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140%;
  height: 0;
  padding-bottom: 140%;
  border-radius: 100%;
  border: 3px solid ${colors.joivy.pink};
  transition: opacity 1s;
  &:hover {
    opacity: 1;
  }
}

@media screen and (${theme.device.desktop}) {
  .red-circle {
    position: relative;
    &::after {
      font-size: 0;
      content: '';
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 130%;
      height: 0;
      padding-bottom: 130%;
      border-radius: 100%;
      border: 3px solid ${colors.joivy.pink};
      transition: opacity 1s;
      pointer-events: none;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
`;
