/** @format */

import React, { memo, ReactNode } from 'react';

import { ThemeProvider as StyledProvider } from 'styled-components';

import theme, { GlobalFontsStyle } from './styled-theme';

interface Props {
  children: ReactNode;
}

export const ThemeProvider = memo(({ children }: Props) => (
  <StyledProvider theme={theme}>
    {children} <GlobalFontsStyle />
  </StyledProvider>
));
