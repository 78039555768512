/** @format */

import React, { memo, ReactNode } from 'react';

import { Provider } from 'react-redux';

import { store } from './helpers/createStore';

interface Props {
  children: ReactNode;
}

export const ReduxProvider = memo(({ children }: Props) => <Provider store={store}>{children}</Provider>);
