/** @format */

import { DEFAULT_CHECK_IN_DATE, DEFAULT_ORDER_BY, LISTING_MAX_ITEMS_PER_PAGE } from '../@ducks/listing/constants';
import { getListingSorting } from '../@ducks/listing/helpers/getListingSorting';
import { TApiBeds } from '../types/apiBeds';
import { DEFAULT_FILTERS_API_SEARCH, TApiSearchParams } from '../types/apiSearch';
import { NAME_PORTAL_CODE } from '../utils/gatsby/constants';
import { Axios } from '../utils/gatsby/createAxiosInstance';
import { TLocale } from '../utils/gatsby/types';

const getPromise = async (data: TApiSearchParams, locale?: TLocale) => {
  const searchEndpont = `/Beds/${NAME_PORTAL_CODE}${locale ? `?language=${locale}` : ''}`;
  return Axios.post<TApiBeds>(searchEndpont, data)
    .then(response => response.data)
    .catch(error => {
      if (error.response && 'data' in error.response)
        throw new Error(
          `[getPromise] Request failed.\n${error}\nErrorBody: ${JSON.stringify(
            error.response.data,
            null,
            2,
          )}\nRequest URL: /Beds/${NAME_PORTAL_CODE}\nData: ${JSON.stringify(data, null, 2)}`,
          {
            cause: 'getPromise',
          },
        );
      throw new Error(`[getPromise] Unexpected Error: ${error}`, {
        cause: 'getPromise',
      });
    });
};

export const apiSearch = async ({
  language,
  query,
  orderBy = DEFAULT_ORDER_BY,
  checkInDate = DEFAULT_CHECK_IN_DATE,
  positions = [],
  pageIndex = 1,
  districtIds,
  filters = DEFAULT_FILTERS_API_SEARCH,
  areas = [],
  zipcodes = [],
}: TApiSearchParams) => {
  try {
    const response = await getPromise(
      {
        query,
        ...getListingSorting(orderBy),
        checkInDate,
        positions,
        pageIndex,
        districtIds,
        filters,
        areas,
        maxItemsPerPage: LISTING_MAX_ITEMS_PER_PAGE,
        zipcodes,
      },
      language,
    );

    return response;
  } catch (error) {
    if ((error as any).cause === 'getPromise') throw new Error(`[apiSearch] > ${error}`, { cause: 'apiSearch' });
    throw new Error(`[apiSearch] Request failed\n${error}`, {
      cause: 'apiSearch',
    });
  }
};
