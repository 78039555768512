/** @format */

import { ALL_LOCALES, BUILD_LOCALES } from '../../utils/gatsby/constants';
import { TLocale, TLocalesObject } from '../../utils/gatsby/types';

export function reduceLocales<T>(
  value: (locale: TLocale) => T,
  options?: { allLocales?: boolean; locales?: TLocale[] },
): TLocalesObject<T> {
  return (options?.allLocales ? ALL_LOCALES : options?.locales ? options.locales : BUILD_LOCALES).reduce<
    TLocalesObject<T>
  >((acc, curr) => {
    acc[curr] = value(curr);
    return acc;
  }, {} as TLocalesObject<T>);
}
