/** @format */

import { TReducerGeneral } from '@/@ducks/general/selectors';
import { TAction } from '@/redux/declarations';

export const reducerGeneral = (
  prevState = {
    GTMListType: ['sorting_by_product_filters'],
  } as TReducerGeneral,
  action: TAction,
): TReducerGeneral => {
  let newState;
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return { ...prevState, currentPage: action.payload };
    case 'SET_LOCALE':
      return {
        ...prevState,
        locale: action.payload,
      };
    case 'RESET_VIEW_MAP':
      return { ...prevState, isViewMap: false };
    case 'SET_ROOM_INDEX':
      return {
        ...prevState,
        roomIndex: action.payload,
      };
    case 'SET_ROOM_LIST_TYPE':
      return {
        ...prevState,
        roomListType: action.payload,
      };
    case 'SET_BOOK_VISIT_FORMID':
      return {
        ...prevState,
        bookVisitFormID: action.payload,
      };
    default:
      return prevState;
  }
};
