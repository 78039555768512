/** @format */

import React, { memo, ReactNode } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ReduxProvider } from './redux/Provider';
import { ThemeProvider } from './theme/Provider';

interface Props {
  children: ReactNode;
}

export const Providers = memo(({ children }: Props) => {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider>{children}</ReduxProvider>
        {process.env.GATSBY_ENV !== 'production' && (
          <ReactQueryDevtools initialIsOpen={false} position="left" buttonPosition="bottom-left" />
        )}
      </QueryClientProvider>
    </ThemeProvider>
  );
});
