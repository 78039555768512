/** @format */

import React, { ReactNode } from 'react';

import { Providers } from './Providers';
import AzureComponent from './services/AppInsights';

interface Props {
  element: ReactNode;
}

export const App = ({ element }: Props) => <Providers>{element}</Providers>;

export default AzureComponent(App);
